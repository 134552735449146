{
  "add-home": "添加捷徑到主頁",
  "add-notes": "添加您的筆記",
  "add-notes-descr": "這是您的私人空間，用來記錄您對葡萄酒旅程的所有想法。",
  "add_homescreen_app": "点击然后添加到主屏幕",
  "age-location-text": "您的 Trivento三風酒廠品酒之旅即將開始。我們需要確認您是否達到合法飲酒年齡以及您所在的地區。",
  "aroma": "香氣",
  "aroma-rate-text": "您對這款葡萄酒的香氣滿意程度如何？",
  "become-member": "成為會員",
  "become-member-text": "\"想要更多冒險？探索更多葡萄酒指南針的角落 [註冊 CTA 按鈕]\n\"",
  "birth-year": "您的出生年份",
  "birth-year-required": "请输入您的出生年份",
  "bodega-trivento": "阿根廷风之语酒庄",
  "build-taste": "建立您的品味檔案",
  "caf-thank-you": "您的反饋將幫助我們辨別並向您展示其他您會喜愛的 Trivento三風葡萄酒❤\uFE0F",
  "choose-bottle": "選擇您想探索的 Trivento三風葡萄酒",
  "choose-option": "選擇體驗",
  "club": "俱樂部",
  "colour": "顏色",
  "colour-aroma": "顏色、香氣、味蕾",
  "colour-rate-text": "您對這款葡萄酒的顏色滿意程度如何？",
  "come-back": "请晚点再来。",
  "confirm": "確認",
  "congrats": "恭喜",
  "contact-us": "聯繫我們",
  "continue": "繼續",
  "country": "居住國家",
  "country-required": "请选择国家以继续",
  "created-pass": "我们为您打造了一个酒窖，现在您可以直接在我们的网站上对葡萄酒进行评价",
  "did-not-receive": "如果你没有收到电子邮件",
  "discover": "發現",
  "edit-profile": "編輯您的個人資料",
  "email": "電子郵件",
  "email-confirm": "您的电子邮件地址已更改",
  "email-required": "请输入您的电子邮件",
  "enjoy": "享受",
  "explore": "探索",
  "explore-portfolio": "探索系列",
  "file-greater": "您的文件大小超过2MB",
  "flavour": "風味",
  "flavour-rate-text": "您對這款葡萄酒的風味滿意程度如何？",
  "forgot-pass": "忘記密碼？",
  "fresh-verification-link": "新的验证链接已发送到您的电子邮件地址",
  "go-to-palate": "去我的味觉专栏",
  "go-to-passport": "[前往我的酒窖]",
  "goes-well": "搭配建議",
  "harvest": "收成",
  "hide-taste": "隐藏我的口味",
  "home": "首頁",
  "i-accept": "我接受",
  "id-be-happy": "當然，我很樂意！ (CTA 按鈕)",
  "journey-descr": "Cruz del Alto，2016年秋季",
  "keep-updated": "保持更新",
  "know-story": "了解故事",
  "legal-drinking": "我已達到合法飲酒年齡",
  "make-note": "添加您的笔记",
  "mendoza-argentina": "門多薩 - 阿根廷",
  "my-cellar": "我的酒窖",
  "my-taste": "符合我的口味",
  "name": "姓名",
  "name-required": "请输入您的姓名",
  "new": "新的",
  "news-for-you": "新消息",
  "no": "否",
  "nothing": "这里还是空的。",
  "ok": "確認",
  "opinion-important": "您的意見很重要。",
  "palate": "您的味蕾",
  "palate-empty": "你的味觉专栏还没有内容",
  "password": "密碼",
  "password-required": "请输入您的密码",
  "podcast": "播客錦集",
  "profile": "您的資料",
  "profile-update-confirm": "配置文件更新成功",
  "promotion": "促銷活動",
  "rate-wine": "評價您的葡萄酒",
  "request-again": "请再要求一次",
  "request-password-reset": "请求密码重置",
  "resend-pass": "重新发送密码恢复说明",
  "resend-pass-again": "您可以在{0}秒内再次重新发送重置密码",
  "reset-pass": "重置密码",
  "reset-pass-email-sent": "重置密码电子邮件已发送",
  "restore-conf-pass": "确认新密码",
  "restore-create-pass": "创建新密码",
  "restore-enter-email": "输入与帐户关联的电子邮件以找回您的密码",
  "restore-new-pass": "新密码",
  "restore-pass": "恢复密码",
  "restore-pass-reset": "您的密码已成功重置。请在登录时使用您的新密码",
  "restore-unic-pass": "密码必须至少包含8个字符",
  "restore-we-have-sent": "我们已向您的电子邮件地址发送了密码找回说明",
  "rights-reserved": "版權所有 2021",
  "same-as-password": "确认密码不匹配",
  "save-changes": "保存更改",
  "save-note": "保存您的记录",
  "save-notes": "保存我的筆記",
  "select-country": "選擇您的居住國家",
  "select-year-birth": "选择您的出生年份",
  "shop": "購物",
  "show-taste": "展示我的口味",
  "sign-in": "登入",
  "sign-out": "注销",
  "sign-up": "註冊",
  "spare-minutes": "您可以花幾分鐘在 Vivino 上給我們評分嗎？",
  "subscribe": "[註冊 CTA 按鈕] 是的，我想探索更多。",
  "subscribed-newsletter": "訂閱電子報",
  "surname": "姓氏",
  "surname-required": "请输入您的姓氏",
  "taste-palate": "您的味蕾",
  "terms-conditions": "我接受條款與條件",
  "thank-you": "謝謝！",
  "thank-you-member": "感谢您加入我们会员",
  "to-all-wines": "返回所有葡萄酒",
  "trivento-wines": "Trivento三風酒廠",
  "type-email": "請輸入您的電子郵件地址",
  "type-name": "输入您的名字",
  "type-surname": "输入您的姓氏",
  "variety": "品種",
  "vendors": "供应商",
  "visit-trivento": "拜訪 Trivento.com",
  "visit-website": "拜訪我們的網站",
  "vivino-website": "[CTA 按鈕] 前往 Vivino",
  "welcome": "歡迎",
  "wine": "葡萄酒",
  "wine-added": "该酒和您的评级已添加至您的护照中",
  "wine-journal": "我的葡萄酒日誌",
  "write-notes": "在這裡寫下您的筆記。",
  "yes": "是",
  "your-cellar": "您的酒窖",
  "your-journey": "您的旅程",
  "your-rate": "您对这款酒的评价",
  "accept-term": "條款與條件",
  "no-promotions": "在商家網站上了解最新的促銷活動。",

  "enable-geolocation": "启用地理定位",
  "geolocation-description": "Trivento Wine Compass希望访问您的位置以提供个性化内容。要启用它，请按照以下步骤操作：",
  "geolocation_step_1": "打开您手机的\"设置\"选项。",
  "geolocation_step_2": "选择\"隐私与安全\"选项。",
  "geolocation_step_3": "选择\"位置\"选项。",
  "geolocation_step_4": "找到您正在使用的浏览器，然后选择\"仅在使用应用时\"选项。",
  "wines": "葡萄酒",
  "experiences": "经验"
}
